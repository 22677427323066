import React from "react";
import {registerUser} from "../../redux/actions/authAction.js"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {

  }
})
export default class Register_Form extends React.Component {
  constructor(props){
super(props);
this.state={
  firstName:'',
  lastName:'',
  password:"",
  pwd:"",
  emailId:""

}
  }


  register = () => {
    this.props.dispatch(registerUser(this.state))
  };
  setFieldValue=({target})=>{

     this.setState({[target.name]:target.value})

   }



  render() {


    return (
      <div class="login-form d-flex flex-column">
        <img class="align-self-center" style={{flex:1}} src="images/logo.png" height="50" width="50" alt="Card image cap" />
        <div class="card bg-white">

 <div class="card-body d-flex flex-column">
      <Input
                       text="First Name"
                       ref="firstName"
                       name="firstName"
                       validator={true}
                       value={this.state.firstName}
                       onChange={this.setFieldValue}
                     mandatory


                       emptyMessage="Application name can't be empty"
                     />

                     <Input
                                   text="Last Name"
                                   ref="lastName"
                                   name="lastName"
                                   validator={true}
                                   value={this.state.lastName}
                                   onChange={this.setFieldValue}
                                 mandatory


                                   emptyMessage="Application name can't be empty"
                                 />
                                  <Input
                                               text="Email Id"
                                               ref="emailId"
                                               name="emailId"
                                               validator={true}
                                               value={this.state.emailId}
                                               onChange={this.setFieldValue}
                                             mandatory


                                               emptyMessage="Application name can't be empty"
                                             />

                                             <Input
                                                           text="Password"
                                                           ref="password"
                                                           name="password"
                                                           validator={true}
                                                           type="passowrd"
                                                           value={this.state.password}
                                                           onChange={this.setFieldValue}
                                                         mandatory


                                                           emptyMessage="Application name can't be empty"
                                                         />
                                                         <Input
                                                                       text="Confirm Password"
                                                                       ref="pwd"
                                                                       name="pwd"
                                                                       validator={true}
                                                                       value={this.state.pwd}
                                                                       onChange={this.setFieldValue}
                                                                     mandatory


                                                                       emptyMessage="Application name can't be empty"
                                                                     />
         <button class="btn btn-outline-primary" onClick={()=>this.register()}>Register</button>
         </div>
         </div>

         </div>

    );
  }
}
