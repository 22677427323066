import {combineReducers} from "redux";
import auth from "./authReducer"
import adsDataSource from "./adsDataSourceReducer"
import screenDetails from "./myScreenReducer"
import screensAdmin from "./screenAdminReducer"
import usersAdmin from "./userAdminReducer"
import adGroupAdmin from "./adGroupAdminReducer"
import socialMediaAdmin from "./socialMediaAdminReducer"
import playReport from "./playReportReducer"
export default combineReducers({
auth,
adsDataSource,
screenDetails,
screensAdmin,
adGroupAdmin,
playReport,
usersAdmin,
socialMediaAdmin
});
