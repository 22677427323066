import React from "react";
import {login} from "../../redux/actions/authAction.js"
import {connect} from "react-redux";
import Input from "../../components/formcomponent/Input"
import Login_Form from "../../components/Auth/Login_Form"
import Reset_Form from "../../components/Auth/Reset_Form"

import Register_Form from "../../components/Auth/Register_Form"
import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {
    isAuthenticated:store.auth.isAuthenticated,
    resetPWD:store.auth.resetPWD,
    isResetted:store.auth.isResetted,
  }
})
export default class Login extends React.Component {
  constructor(props){
    super(props);
    this.state={
      componentID:'L_1',
      shouldRemainOnLogin : true,
    }
  }

  componentWillReceiveProps(nextProps){
    console.log("In Login (this.props.isAuthenticated="+nextProps.isAuthenticated);
    console.log("In Login this.props.resetPWD="+this.props.resetPWD);
    console.log("In Login (nextProps.resetPWD="+nextProps.resetPWD);
    console.log("In Login (nextProps.isResetted="+nextProps.isResetted);

    if(localStorage.getItem("userObj")!==null){
    console.log("In Login userID="+JSON.parse(localStorage.getItem("userObj")).userID);
    //If Reset of password is done for otp_test, we want OTP to be validated on Reset page,
    //so user should remain on Reset..Else it was going to Login form  
    //added the else part for shouldRemainOnLogin to true for coming to Login page after reset if OTP invalid  
    if(JSON.parse(localStorage.getItem("userObj")).userID=='66ebd8db7b88a242c8e1d028' 
    && nextProps.isResetted){
      console.log("settng shouldRemainOnLogin to false!")
      this.setState({shouldRemainOnLogin:false})
    } else {
      this.setState({shouldRemainOnLogin:true})
    }
    } else {
      this.setState({shouldRemainOnLogin:true})

    }
    
  }



   getRedirectPath() {
    const locationState = this.props.location.state
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname // redirects to referring url
    } else {
      return '/'
    }
  }


  render() {
    if(this.props.isAuthenticated && !this.props.resetPWD && 
      JSON.parse(localStorage.getItem("userObj")).userID!=="66ebd8db7b88a242c8e1d028"
    ){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)
    }
    // let { from } = this.props.location.state || { from: { pathname: "/" } };
    // let { redirectToReferrer } = this.state;
    //
    // if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div class="login-page d-flex align-items-center flex-column justify-content-center h-100">

    {this.state.componentID=="L_1"?(!this.props.resetPWD && this.state.shouldRemainOnLogin?<Login_Form />:<Reset_Form />):<Register_Form />}
    {this.state.componentID=="L_1"?<a class="pointer d-none " onClick={()=>this.setState({componentID:'R_2'})} >Register</a>:<a class="pointer" onClick={()=>this.setState({componentID:'L_1'})} >Already a member? Sign in.</a>}

</div>
    );
  }
}
