import React from "react";
import {resetPassword} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import i18n from "../../i18n";	
import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {
isResetting:store.auth.isResetting,
  }
})
export default class Reset_Form extends React.Component {
  constructor(props){
super(props);
this.state={

  userName :JSON.parse(localStorage.getItem("userObj")).userName,
  password:"",
  newPassword:"",
  confirmPassword:"",

}
  }


  validatePWD = (value) => {
    return this.state.newPassword==value
  };
  resetPassword = () => {
    this.props.dispatch(resetPassword(this.state))
  };

  setFieldValue=({target})=>{

     this.setState({[target.name]:target.value})

   }

   componentDidMount(){
    if(localStorage.getItem("locale")==null){
      console.log("setting locale");
      var localeFromUser = JSON.parse(localStorage.getItem("userObj")).locale;
      if(localeFromUser==null){
        localeFromUser = "en";
      }
      console.log("localeFromUser="+localeFromUser);
      localStorage.setItem("locale",localeFromUser);
      i18n.changeLanguage(localeFromUser);
    }
   }


  render() {


    return (


      <div class="login-form d-flex no-shadow border-0  card" style={{height:"400px",width:"350px",background:"transparent"}}>

        <div class="d-flex align-items-center justify-content-center" style={{height:"100px"}}>
  <img   src="images/login.png" height="100"  alt="Card image cap" />

</div>
<div class="flex-1 d-flex align-items-start justify-content-center">

<div class="card-body d-flex flex-column" >
                          <Input
                                       text={i18n.t('reset.username')}
                                       ref="userName"
                                       name="userName"
                                       validator={true}
                                       disabled={true}
                                       theme={"transparent"}
                                       value={this.state.userName}
                                       onChange={this.setFieldValue}
                                     mandatory


                                       emptyMessage="Application name can't be empty"
                                     />

                                     <Input
                                                   text={i18n.t('reset.oldpwd')}
                                                   ref="password"
                                                   name="password"
                                                   validator={true}
                                                      theme={"transparent"}
                                                   type="password"

                                                   value={this.state.password}
                                                   onChange={this.setFieldValue}
                                                 mandatory


                                                   emptyMessage="Application name can't be empty"
                                                 />
                                                 <Input
                                                               text={i18n.t('reset.newpwd')}
                                                               ref="newPassword"
                                                               name="newPassword"
                                                               validator={true}
                                                                  theme={"transparent"}
                                                               type="password"
                                                               minChars={6}
                                                               maxChars={40}

                                                               value={this.state.newPassword}
                                                               onChange={this.setFieldValue}
                                                             mandatory


                                                               emptyMessage="Application name can't be empty"
                                                             />
                                                             <Input
                                                                           text={i18n.t('reset.confirmpwd')}
                                                                           ref="confirmPassword"
                                                                           name="confirmPassword"
                                                                           validator={true}
                                                                              theme={"transparent"}
                                                                           type="password"
                                                                           value={this.state.confirmPassword}
                                                                           onChange={this.setFieldValue}
                                                                         mandatory
                                                                          validate={this.validatePWD}
                                                                          errorMessage={i18n.t('reset.mismatch')}
                                                                           emptyMessage="Application name can't be empty"
                                                                         />

 <ActionButton containerClass={`${this.props.isResetting?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.resetPassword()}>{i18n.t('reset.reset')}</ActionButton>
</div>
</div>
</div>


    );
  }
}
