export default function reducer(state = {

  socialMediaFetching: false,
  socialMediaFetched: false,
  socialMediaList: [],
  addSocialMediaProfileStatus : null,
  removeSocialMediaProfileStatus : null,
  instagramAuthUrl:null,
  instagramProfiles:[],
  tokenGenerated : false,
  fbTokenGenerated : false,
  clientId : null,
  instagramStoryMessage:""

}, action) {

  switch (action.type) {

      case "FETCH_SOCIALMEDIAPROFILES_ADMIN":
      return {
        ...state,
        socialMediaFetched: false,
        socialMediaFetching: true,
      }

      case "FETCH_SOCIALMEDIAPROFILES_ADMIN_SUCCESS":
        console.log("in reducer social Media list="+JSON.stringify(action.payload))
       return {
      ...state,
      socialMediaFetched: true,
      socialMediaFetching: false,
      socialMediaList:action.payload,
    }

    case "REFRESH_SOCIALMEDIAPROFILES":
      return {
        ...state,
        socialMediaFetched: false,
        socialMediaFetching: true
      }

           
    case "ADD_SOCIAL_MEDIA_PROFILE":{
      return {
        ...state,
        addSocialMediaProfileStatus:0,
        instagramStoryMessage:""
      }
    }
    case "ADD_SOCIAL_MEDIA_PROFILE_SUCCESS":{
      var message = action.payload.data.retValue;
      
      var newData=action.payload.data;
      
      console.log("newData="+newData)
      if(!!message){
        return {
          ...state,
          addSocialMediaProfileStatus:1,
          instagramStoryMessage:message
        }
      }else{
        return {
          ...state,
          addSocialMediaProfileStatus:1,
  
          socialMediaList:[...state.socialMediaList,newData]
        }
      }
      
    }

    case "ADD_SOCIAL_MEDIA_PROFILE_FAILURE":{
      return {
        ...state,
        addSocialMediaProfileStatus:-1
      }
    }

    case "REMOVE_SOCIAL_MEDIA_PROFILE":{
      return {
        ...state,
        removeSocialMediaProfileStatus:0
      }
    }
    case "REMOVE_SOCIAL_MEDIA_PROFILE_SUCCESS":{

      var newData=action.payload.data;

      return {
        ...state,
        removeSocialMediaProfileStatus:1,
        instagramStoryMessage:false,
        socialMediaList:state.socialMediaList.filter((item,i)=>(item.socialMediaType!=action.payload.socialMediaType || 
        item.profileName!=action.payload.profileName))
      }
    }

    case "INSTAGRAM_AUTH_URL_SUCCESS":
      return {
        ...state,
        instagramAuthUrl : action.payload,
      }

    case "INSTAGRAM_PROFILES_SUCCESS":
      return {
        ...state,
        instagramProfiles : action.payload,
      }
 
    case "INSTAGRAM_GENERATETOKEN_SUCCESS":
      // var tempArr=state.instagramProfiles.map((profile)=>{
      //   if(profile._id==action.payload._id){
      //     var newData=update(profile,{$merge:action.payload});

      //     return newData
      //   }else{
      //     var newData=profile;

      //     return newData
      //   }
      // })
      return {
        ...state,
        //instagramProfiles : tempArr,
        tokenGenerated : true
      }
      case "FACEBOOK_TOKEN_SUCCESS":{

        var newData=action.payload.data.record;
        var oldData = state.socialMediaList;
        var retValue = action.payload.data.retValue;
        console.log("newData="+JSON.stringify(newData))
        console.log("retValue="+retValue)
        console.log("oldData="+JSON.stringify(oldData))
        var newSocialMediaList = oldData.length>0?oldData.map((obj)=>{
          if(obj.socialMediaType==newData.socialMediaType && obj.profileName==newData.profileName){
            return newData;
          }else{
            return obj;
          }
        }): newData;
        console.log("newSocialMediaList="+newSocialMediaList)

        return {
          ...state,
          addSocialMediaProfileStatus:1,
          fbTokenGenerated:true,
          //socialMediaList:[...state.socialMediaList,newData]
          socialMediaList : newSocialMediaList,
          instagramStoryMessage : retValue
        }
      }

      case "FACEBOOK_CLIENT_ID":
        return{
          ...state,
          clientId : action.payload.data,
          instagramStoryMessage:""
        }
        case "FACEBOOK_STORY_MESSAGE":
          console.log("action.payload.data="+JSON.stringify(action.payload.data))
          return{
            ...state,
            instagramStoryMessage : action.payload.data
          }
        case "GET_FACEBOOK_STORY_MESSAGE":
          return{
            ...state,
            instagramStoryMessage:"",
            fbTokenGenerated:false
          }
 
  }
  return state;
}
