import React,{Component} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
// Utils
// import auth from '../../utils/auth';

  const PrivateRoute = ({ component: ComposedComponent, ...rest }) => {
    @connect((store)=>{
      return {
        isAuthenticated:store.auth.isAuthenticated,
        resetPWD:store.auth.resetPWD
      }
    })
  class Authentication extends Component {

    // redirect if not authenticated; otherwise, return the component imputted into <PrivateRoute />
    handleRender(props) {

      if (!this.props.isAuthenticated || this.props.resetPWD) {
        return <Redirect to={{
          pathname: '/login',
          state: {
            from: props.location,
            message: 'You need to sign in'
          }
        }}/>
      } else {
        return <ComposedComponent {...props}/>
      }
    }

    render() {
      return (
        <Route {...rest} render={this.handleRender.bind(this)}/>
      )
    }
  }


return <Authentication/>


}
export default PrivateRoute
