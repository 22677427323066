import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import {AG_GRID_LOCALE_EN} from '../lang/locale.en';
import {AG_GRID_LOCALE_FR} from '../lang/locale.fr';

import store from './store';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import "../css/bootstrap.css";
import "../css/customStyle.css";
import "../css/all.css";
import {isTokenValid} from "./utils/utility.js"
import AppContainer from "./pages/AppContainer";
import i18n from './i18n';
import { Redirect } from "react-router-dom";
//import canva from './canva';

const token = localStorage.getItem('token')

if (token) {
  if(isTokenValid(token)){
    if(JSON.parse(localStorage.getItem("userObj")).resetPWD==false){
      store.dispatch({type: "AUTH_SUCCESS",payload:false})

    }else{
        store.dispatch({type: "UN_AUTH"})
    }
  }else{
    store.dispatch({type: "UN_AUTH"})
  }

}


console.log("here checking lang")
var lang = localStorage.getItem("locale");
  console.log("lang="+lang)
  if(lang=="en"){
    i18n.changeLanguage('en');
    localStorage.setItem("locale","en")
  }else if(lang=="fr"){
    i18n.changeLanguage('fr');
    localStorage.setItem("locale","fr")
  }
  
  window.AG_GRID_LOCALE_EN = AG_GRID_LOCALE_EN;
  window.AG_GRID_LOCALE_FR = AG_GRID_LOCALE_FR;

  
 
ReactDOM.render(
<Provider store={store}>
  <AppContainer />
</Provider>
  , document.getElementById("app"));

  
