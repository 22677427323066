import React,{Component} from "react";
import ModalWrapper from "../../components/wrapper/ModalWrapper"
import {  Trans } from 'react-i18next';	
import i18n from "../../i18n";	

export default class IdleTimeoutModal extends Component {
  

  render(){

    return(
      <ModalWrapper closeHandler={()=>this.props.closeHandler()} handleLogout={()=>this.props.handleLogout()} title={i18n.t('logout.idletimeout')} style={{height:"30vh",width:"30vw"}}>
      <div class="d-flex w-100  py-2" >

<div class="d-flex w-100" >
<div class="d-flex flex-column py-3 h-100 w-100">

 <div><Trans i18nKey="logout.idletimeoutmsg"/></div>

   
    
</div>
</div>

  </div>
  <div class="d-flex justify-content-center align-items-center btn-group" style={{height:"90px"}}>
  <button class="btn btn-lite" onClick={()=>this.props.handleLogout()}><Trans i18nKey="logout.logout"/></button>
 <button class="btn btn-info" onClick={()=>this.props.closeHandler()}><Trans i18nKey="logout.continue"/></button>
  </div>
  </ModalWrapper>
    )
  }
}
