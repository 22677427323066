import React from "react";
import {resetPassword,validateOTP,loginAgain} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import i18n from "../../i18n";	
import Countdown from 'react-countdown';
import {setPassword} from "../../utils/utility.js"
import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {
isResetting:store.auth.isResetting,
isResetted:store.auth.isResetted,
otpValid:store.auth.otpValid
  }
})
export default class Reset_Form extends React.Component {
  constructor(props){
super(props);
this.state={

  userName :JSON.parse(localStorage.getItem("userObj")).userName,
  password:"",
  newPassword:"",
  confirmPassword:"",
  countdownTime:0
}
  }


  validatePWD = (value) => {
    return this.state.newPassword==value
  };
  resetPassword = () => {
    this.props.dispatch(resetPassword(this.state))
  };

  setFieldValue=({target})=>{

     this.setState({[target.name]:target.value})

   }

   componentDidMount(){
    if(localStorage.getItem("locale")==null){
      console.log("setting locale");
      var localeFromUser = JSON.parse(localStorage.getItem("userObj")).locale;
      if(localeFromUser==null){
        localeFromUser = "en";
      }
      console.log("localeFromUser="+localeFromUser);
      localStorage.setItem("locale",localeFromUser);
      i18n.changeLanguage(localeFromUser);
    }
   }

   //Methods for OTP validation
   componentWillReceiveProps(nextProps){
    console.log("***this.props.isResetted="+nextProps.isResetted);
  if(localStorage.getItem("userObj")!==null && JSON.parse(localStorage.getItem("userObj")).userID=="66ebd8db7b88a242c8e1d028"){
    this.setState({needToCheckOTP:true});
    console.log("***nextProps.otpValid="+nextProps.otpValid)
    if(nextProps.otpValid!==null && nextProps.otpValid=="valid"){
      console.log("OTP is valid!");
      this.setState({otpValid:true})
    } else {
      console.log("OTP is invalid!");
      this.setState({otpValid:false,countdownTime:Date.now() + 120000})
    }
  }
  }

  checkOTP = () =>{
    if(this.state.otp!==""){
      //this.setState({otpValid : true})
      this.props.dispatch(validateOTP(JSON.parse(localStorage.getItem("userObj")).userID,this.state.otp));
    }
  }
  loginAgain = () =>{
    this.setState({needToCheckOTP:false,otpExpired:false,});
    this.props.dispatch(loginAgain());  
    
  }
  
  countdownCompleted = () =>{
    console.log("countdown completed!");
    this.setState({otpExpired:true});
  }
  
  resendOTP = () =>{
    this.setState({otpExpired:false,otpValid:false});
    if(this.state.userName!=="" && this.state.password!==""){
      this.state.pass= this.state.password
      var hash = setPassword(this.state.password)
      // console.log(hash)
      
      this.state.password =hash;
      this.props.dispatch(login(this.state)); 
    }
    
  }

  getRedirectPath() {
      return '/'
  }


  render() {

    if(this.state.otpValid){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)
    }
    return (


      <div class="login-form d-flex no-shadow border-0  card" style={{height:"400px",width:"350px",background:"transparent"}}>

        <div class="d-flex align-items-center justify-content-center" style={{height:"100px"}}>
  <img   src="images/login.png" height="100"  alt="Card image cap" />

</div>
<div class="flex-1 d-flex align-items-start justify-content-center">

<div class="card-body d-flex flex-column" >
                          <Input
                                       text={i18n.t('reset.username')}
                                       ref="userName"
                                       name="userName"
                                       validator={true}
                                       //disabled={true}
                                       theme={"transparent"}
                                       value={this.state.userName}
                                       onChange={this.setFieldValue}
                                     mandatory

                                      disabled={this.state.needToCheckOTP}
                                       emptyMessage="Application name can't be empty"
                                     />

                                     <Input
                                                   text={i18n.t('reset.oldpwd')}
                                                   ref="password"
                                                   name="password"
                                                   validator={true}
                                                      theme={"transparent"}
                                                   type="password"

                                                   value={this.state.password}
                                                   onChange={this.setFieldValue}
                                                 mandatory


                                                   emptyMessage="Application name can't be empty"
                                                 />
                                                 {!this.state.needToCheckOTP?
                                                  <div>
                                                 <Input
                                                               text={i18n.t('reset.newpwd')}
                                                               ref="newPassword"
                                                               name="newPassword"
                                                               validator={true}
                                                                  theme={"transparent"}
                                                               type="password"
                                                               minChars={6}
                                                               maxChars={40}

                                                               value={this.state.newPassword}
                                                               onChange={this.setFieldValue}
                                                             mandatory


                                                               emptyMessage="Application name can't be empty"
                                                             />
                                                             </div>:null}
                                                             {!this.state.needToCheckOTP?
                                                              <div>
                                                             <Input
                                                                           text={i18n.t('reset.confirmpwd')}
                                                                           ref="confirmPassword"
                                                                           name="confirmPassword"
                                                                           validator={true}
                                                                              theme={"transparent"}
                                                                           type="password"
                                                                           value={this.state.confirmPassword}
                                                                           onChange={this.setFieldValue}
                                                                         mandatory
                                                                          validate={this.validatePWD}
                                                                          errorMessage={i18n.t('reset.mismatch')}
                                                                           emptyMessage="Application name can't be empty"
                                                                         />
                                                                         </div>:null}
                                                                         {this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
                                                                        <div>
                                                                        <div style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                                        <span style={{textAlign:"center",margin:"auto"}}><button style={{border:"0px", color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}}>An OTP has been sent to your registered email ID.</button></span>
                                                                        </div>
                                                                        <div style={{textAlign:"center",border:"0px", color:"#4fbfbd",background:"transparent"}}><Countdown date={this.state.countdownTime} onComplete={()=>this.countdownCompleted()}/></div>
                                                                        <div>
                                                            <Input
                                                                          text="OTP"
                                                                          ref="otp"
                                                                          name="otp"
                                                                          validator={true}
                                                                              theme={"transparent"}
                                                                          type="password"
                                                                          value={this.state.otp}
                                                                          onChange={this.setFieldValue}
                                                                        mandatory


                                                                          emptyMessage="OTP can't be empty"
                                                                        />
                                                                        </div></div>:null
                                                                        }

 {/* <ActionButton containerClass={`${this.props.isResetting?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.resetPassword()}>{i18n.t('reset.reset')}</ActionButton> */}
 <div style={{textAlign:"center"}}>
{this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
<ActionButton containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.checkOTP()} style={{width:"100%"}}>Validate OTP</ActionButton>
:null}
{!this.state.needToCheckOTP?  
 <ActionButton style={{width:"100%"}} containerClass={`${this.props.isResetting?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.resetPassword()}>Reset</ActionButton>
 :null}
 </div>
 {this.props.isResetted && this.props.otpValid=="invalid" && this.state.needToCheckOTP && !this.state.otpExpired?<div><p style={{color:"#B22222"}}>OTP entered is invalid! Please try again.</p><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
 {this.props.isResetted && this.state.otpExpired && this.state.needToCheckOTP?<div><p style={{color:"#B22222"}}>OTP has expired! </p>
 {/* <button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.resendOTP()}>Resend OTP</button> */}
 <button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
</div>
</div>
</div>


    );
  }
}
