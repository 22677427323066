import update from 'immutability-helper';
export default function reducer(state = {

  adGroupFetching: false,
  adGroupFetched: false,
isUpdated:false,
  adGroupData: [],
  addAdGroupStatus:null

}, action) {

  switch (action.type) {

      case "FETCH_ADGROUP_ADMIN":
      return {
        ...state,
        adGroupFetched: false,
        adGroupFetching: true
      }

      case "FETCH_ADGROUP_ADMIN_SUCCESS":
      return {
        ...state,
        adGroupFetched: true,
        adGroupFetching: false,
        adGroupData:action.payload
      }

      case "ADD_ADGROUP":{
        return {
          ...state,
          addAdGroupStatus:0
        }
      }
      case "ADD_ADGROUP_SUCCESS":{

        return {
          ...state,
          addAdGroupStatus:1,

          adGroupData:[...state.adGroupData,action.payload.data]
        }
      }
      case "UPDATE_ADGROUP_SUCCESS":{

        var tempArr=state.adGroupData.map((item)=>{
          if(item.adGroupName==action.payload.adGroupIdentifier){
            return Object.assign(item,action.payload.data)
          }else{
            return item
          }
        })
        console.log("array after updating ad group="+JSON.stringify(tempArr))
        return {
          ...state,
          addAdGroupStatus:1,
          isUpdated:true,
          adGroupData:tempArr
        }
      }
      case "ADD_ADGROUP_FAILURE":{
        return {
          ...state,
          addAdGroupStatus:-1
        }
      }
      case "UPDATE_ADGROUP_FINISHED":{
        return {
          ...state,
          isUpdated:false
        }
      }

  }
  return state;
}
