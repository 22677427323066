import axios from "axios"

import {HOST} from "../../constants/CONSTANTS"
var axiosInstance = axios.create({
  baseURL: HOST,
  timeout: 2000000,


});

axios.defaults.headers.common['Auth-Token'] = localStorage.getItem("token");
module.exports = axiosInstance;
