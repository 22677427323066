import React,{Component} from "react";
import "../../../css/widgets/widget.css";
import {updateStatusOfUploadedFiles} from "../../redux/actions/adAdminAction";
import {connect} from "react-redux";

let instance;
@connect((store)=>{
    return {      
}
})
export default class Ad_Upload_Progress extends Component {
  constructor () {
   super();
   this.timer=0;
   this.imageExtensions=[".jpg", ".jpeg", ".gif", ".webp",".tif",".tiff",".png"];
   this.state={
     visible:"hidden",
     done:false,
     progress:0,
     adsAdded:[],
     filesArr:[],
     height:"20vh",
     collapse:false,
   }
  }
  componentDidMount(){
    console.log("***Ad upload progrmponent did mount");
    var visible = localStorage.getItem("aduploadProgressVisibility")?localStorage.getItem("aduploadProgressVisibility"):"hidden";
    console.log("***visible="+visible);
    var filesArr = JSON.parse(localStorage.getItem("filesArr"));
    this.setState({visible,filesArr});
    if (instance) {
      console.log("only 1 instance allowed!");
      //var filesArr = JSON.parse(localStorage.getItem("filesArr"));
      console.log("filesArr from Local storage ="+JSON.stringify(filesArr));
      var height=filesArr!==null?filesArr.length*8+15:15;
      var heightToSet = height>15?height+"vh":"23vh";
      console.log("*****HEIGHT 1="+heightToSet);
      this.setState({filesArr,height:heightToSet});
      
      if(filesArr!==null && filesArr.length>0){
        this.props.dispatch(updateStatusOfUploadedFiles(filesArr));
      }
      clearInterval(this.timer);
      let self=this;
      if(filesArr!==null && filesArr.length>0){
      self.timer=setInterval(function(){
        console.log("Checking status of ads!")
        self.setState({visible:localStorage.getItem("aduploadProgressVisibility")?localStorage.getItem("aduploadProgressVisibility"):"hidden"});
        var currentFilesInLS = JSON.parse(localStorage.getItem("filesArr"));
        self.setState({filesArr:currentFilesInLS});
        var numOfFilesPendingProcessing = 0;
        currentFilesInLS.map((obj)=>{
          if(obj.progress==0){
            numOfFilesPendingProcessing++;
          }
        });
        if(currentFilesInLS!== null && currentFilesInLS.length>0 && numOfFilesPendingProcessing>0){
          self.props.dispatch(updateStatusOfUploadedFiles(currentFilesInLS));
        }
        
      },10000);
    }
      //this.setState({visible:this.props.progressBarVisibility})
      //throw new Error("You can only create one instance!");
      return this;
    }
    instance = this;
      
    // if(!this.state.done){
    //   this.startTimer();
    // }
    
  }
  componentWillReceiveProps(nextProps){    
    //   this.setState({});
    // }
    console.log("***Ad upload comp will rec props"+JSON.stringify(nextProps.progressBarVisibility));
    var visible = localStorage.getItem("aduploadProgressVisibility")?localStorage.getItem("aduploadProgressVisibility"):"hidden";
    console.log("visible??"+visible)
    //this.setState({visible:localStorage.getItem("adUploadProgressVisible")})
    this.setState({visible});
    //commented this
    // var filesArrToSend = nextProps.filesArr.map((obj)=>{
    //   return {name:obj,progress:0}
    // });
    var filesTotal = JSON.parse(localStorage.getItem("filesArr")); //filesTotal
    //var filesTotal = filesArrFromLS!==null ?filesArrToSend.concat(filesArrFromLS):filesArrToSend;
    if(filesTotal==null){
      filesTotal = [];
    }
    var height=filesTotal.length*8+15;
    var heightToSet = height>15?height+"vh":"23vh";
    console.log("*****HEIGHT 2="+heightToSet);
    this.setState({filesArr:filesTotal});
    this.setState({height:heightToSet});
     //var filesArr = {name:nextProps.filesArr[0],progress:0};
     
     console.log("**filesTotal="+JSON.stringify(filesTotal));
     localStorage.setItem("filesArr",JSON.stringify(filesTotal));
     //localStorage.setItem("aduploadProgressVisibility",nextProps.visibility);
//console.log("from local storage="+JSON.parse(localStorage.getItem("filesArr")).toString())
    clearInterval(this.timer);
    let self=this;
    self.timer=setInterval(function(){
      console.log("Checking status of ads!")
      var currentFilesInLS = JSON.parse(localStorage.getItem("filesArr"));
      self.setState({filesArr:currentFilesInLS});
      var numOfFilesPendingProcessing = 0;
      if(currentFilesInLS!==null && currentFilesInLS.length>0){
      currentFilesInLS.map((obj)=>{
        if(obj.progress==0){
          numOfFilesPendingProcessing++;
        }
      });
    }
      if(currentFilesInLS!==null && currentFilesInLS.length>0 && numOfFilesPendingProcessing>0){
        self.props.dispatch(updateStatusOfUploadedFiles(currentFilesInLS));
      }      
    },10000);

    /*axios.post(`/api/quick-upload`, nextProps.formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              console.log("****percentCompleted="+percentCompleted)
              self.setState({progress:percentCompleted});
              filesArr = {name:self.props.filesArr[0],progress:percentCompleted};
              localStorage.setItem("filesArr",filesArr);
          }
    }).then(response => {
      console.log("got response files="+response.data)
      this.setState({uploadComplete:true});
      //console.log("response data="+JSON.stringify(response.data))
      this.props.addToAdsArr();
      //this.setState({adsAdded:response.data});
      // handle your response;
    }).catch(error => {
      this.setState({errorUploading:true})
      // handle your error
    });*/
      
  }
  
  componentWillUnmount(){
    console.log("component will unmount")
    this.timer=0;
    //localStorage.setItem("aduploadProgressVisibility","hidden");
   this.setState({
     progress:0,
     //visible:"visible"
   });
  }
  hideProgressBar = () =>
  {
    this.setState({visible:"hidden"});
    localStorage.setItem("aduploadProgressVisibility","hidden");
    var newFilesInLS = this.state.filesArr.filter((file)=>{
      if(file.progress==0){
        return file;
      }
    });
    if(newFilesInLS.length==0){
      clearInterval(this.timer);
    }
    localStorage.setItem("filesArr",JSON.stringify(newFilesInLS));
    this.props.openProgressBar("hidden");
  }

  collapseProgressBar = () =>{
    console.log("***collapse progress bar!")
    var currentCollapse = this.state.collapse;
    if(!currentCollapse){
      this.setState({height:"9vh"});
    } else {
      var filesTotal = JSON.parse(localStorage.getItem("filesArr")); //filesTotal
      var height=filesTotal.length*8+15;
      var heightToSet = height>15?height+"vh":"23vh";
      console.log("*****HEIGHT 3="+heightToSet);
      this.setState({height:heightToSet});
    }
    this.setState({collapse:!currentCollapse});
  }
  render(){
      return(
        <div id="adUpload" style={{display:"flex",backgroundColor:"#0000009e",height:this.state.height,width:"30vw",position: "fixed",right: "20px"
        ,bottom: "0",textAlign: "right",border:"0px solid red", visibility:this.state.visible,fontFamily:"Inter",maxHeight:'90vh'}}>{/*localStorage.getItem("adUploadProgressVisible")*/}
        <div class="card  bg-white" style={{width:"100%",height:"100%",border:"0px solid red",padding:"0rem"}}>
        <div class="card-header" style={{padding:"1rem",width:"100%",backgroundColor:"#202124",color:"#babcc0"}}>
            <div class="card-caption">
            <div class="card-icon">

            </div>
            <div class="card-title">
            <span class="main-title">Upload status</span>
              <span class="sub-title"></span>
            </div>
            </div>

            <div class="action-buttons" style={{height:"100%"}}>
            <a class="btn btn-circle  btn-icon-only"  onClick={()=>this.collapseProgressBar()}>{!this.state.collapse?<i class="fas fa-chevron-down"></i>:<i class="fas fa-chevron-up"></i>}</a>
              <a class="btn btn-circle  btn-icon-only" onClick={()=>this.hideProgressBar()}><i class="fa fa-times fa-7x"></i></a>

            </div>
            </div>
        <div style={{height:"100%",width:"100%",border:"0px solid blue",maxHeight:'72vh',overflowY:'auto'}}>
          <div style={{height:"100%", width:"100%",display:"flex",flexDirection:"column"}}>
            {/* <div style={{width:"80%"}}>{localStorage.getItem("filesArr")?localStorage.getItem("filesArr").name:""}</div> */}
            {this.state.filesArr?
            this.state.filesArr.map((obj)=>
            <div style={{padding:"1rem",display:"flex",flexDirection:"row",borderBottom:"1px solid black"}}>
            <div style={{width:"10%",textAlign:"center"}}>
            {this.imageExtensions.includes(obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase())?
            <i class="fas fa-image fa-x"></i>:
            obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".mp4"?
            <i class="fas fa-file-video"></i>:
            obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".pdf"?
            <i class="fas fa-file-pdf"></i>:
            obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".doc" || obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".docx"?
            <i class="fas fa-file-word"></i>:
            obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".xls" || obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".xlsx"?
            <i class="fas fa-file-excel"></i>:
            obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".ppt" || obj.name.substring(obj.name.lastIndexOf(".")).toLowerCase()==".pptx"?
            <i class="fa fa-file-powerpoint"></i>:
            <i class="fas fa-list-ul"></i>
            }</div>
            <div style={{width:"80%",textAlign:"left"}}>{obj.name.substring(obj.name.indexOf("-")+1)}</div>
            <div style={{width:"10%"}}>
            {obj.progress==100?
              <i class="fa fa-check" aria-hidden="true" style={{color:"green"}}></i>:
              <i class="fa fa-spinner fa-spin" style={{color: "#ffbf00"}}></i>
            }
            {/* <CircularProgressbar  value={localStorage.getItem("filesArr")?localStorage.getItem("filesArr").progress:0} text={`${JSON.stringify(localStorage.getItem("filesArr")).progress}%`} /> */}
            </div>
            </div>            
            )
            :null}
            
            {/* {localStorage.getItem("filesArr")?localStorage.getItem("filesArr").progress==100? */}
            
          </div>
        </div>
        </div>
        </div>
        )
        {/* <div style={{height:"10%"}}>&nbsp;</div> */}
        // <FlexiblePositionWrapper closeHandler={()=>this.props.closeHandler()} title="Progress" style={{height:"20vh",width:"30vw"}}>
        {/* <div class="d-flex w-100  py-2">

        <div class="d-flex w-100" >
        <div class="d-flex flex-column py-3 h-100 w-100">
        <div class="form-row w-100 h-10" style={{border:"1px solid blue"}}>
        <div class="col-6 h-10 w-10">Name of file</div>
        <div class="col-6 " style={{width:"10%",border:"1px solid blue"}}><CircularProgressbar value={66} text={`${66}%`} /></div>
        </div>
        </div>
        </div>
        
        </div> */}
        
        // </FlexiblePositionWrapper>
      
  }
}

