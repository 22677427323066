export default function reducer(state={
isAuthenticated:false,
isAuthenticating:false,
resetPWD:false,
isResetted:false,
isResetting:false,
initialState:true,
sendingResetEmail:false,
sentResetEmail:false,
resetEmailError:false,
otpValid:null,
errorCode:"",
customerDetails:null
},action){
  switch (action.type) {
    case "AUTH_INIT":
    return {
      ...state,
      isAuthenticating:true,
      resetPWD:false,
      initialState:true
    }
    case "AUTH_SUCCESS":

return {
  ...state,
  isAuthenticated:true,
  isAuthenticating:false,
  resetPWD:action.payload,
  initialState:true,
  sentResetEmail:false,
  resetEmailError:false,
  errorCode:""
}
case "AUTH_FAILED":

return {
...state,
isAuthenticated:false,
isAuthenticating:false,
resetPWD:false,
initialState:false,
sentResetEmail:false,
resetEmailError:false,
errorCode:action.payload.code
}
case "UN_AUTH":

return {
...state,
isAuthenticated:false,
isAuthenticating:false,
resetPWD:false,
initialState:true,
errorCode:""
}

case "RESET_PWD":
return {
  ...state,
  isResetting:true,
  isResetted:false,
  initialState:true,
  errorCode:""
  
}
case "RESET_PWD_SUCCESS":

return {
...state,
isResetted:true,
isResetting:false,
resetPWD:false,
initialState:true,
errorCode:""

}
case "RESET_PWD_FAILED":

return {
...state,
isAuthenticated:false,
isAuthenticating:false,
resetPWD:false,
isResetted:true,
isResetting:false,
initialState:true,
errorCode:""
}

case "SENDMAIL_RESET":
    return {
      ...state,
      sendingResetEmail:true,
      sentResetEmail:false,
      resetEmailError:false,
      initialState:true,
      errorCode:""
    }
case "SENDMAIL_RESET_SUCCESS":
  console.log("sent reset email to true")
  return {
    ...state,
    sendingResetEmail:false,
    sentResetEmail:true,
    resetEmailError:false,
    initialState:true,
    errorCode:""
  }    
case "SENDMAIL_RESET_FAILED":
  console.log("setting email error to true")
  return {
    ...state,
    sendingResetEmail:false,
    sentResetEmail:false,
    resetEmailError:true,
    initialState:true,
    errorCode:""
  }  

 case "SET_LOCALE" : 
 return {
   ...state
 }

case "GET_CUSTOMERDETAILS":
    return{
      customerDetails:null,
      ...state
    }
    case "GET_CUSTOMERDETAILS_SUCCESS":
      console.log("action.payload="+JSON.stringify(action.payload))
      return{
        ...state,
        customerDetails:action.payload,
        
      } 
  }
  return state;
}
