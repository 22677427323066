import React from "react";
import {login,sendEmailForReset,redirectCanva,validateOTP,loginAgain} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import ComponentLoader from "../../components/uilib/ComponentLoader";
import DialogBox from "../../components/DialogBox"
import DialogBoxLongMessage from "../../components/DialogBoxLongMessage"
import  Breakpoint  from '../../components/uilib/Breakpoint'
import axios from "../../utils/axiosInstance"

import detectBrowserLanguage from 'detect-browser-language'
import {LANGUAGES} from "../../../constants/CONSTANTS";
import i18n from '../../i18n';
import {  Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom'
import Countdown from 'react-countdown';

@connect((store)=>{
  return {
isAuthenticating:store.auth.isAuthenticating,
isAuthenticated:store.auth.isAuthenticated,
initialState:store.auth.initialState,
sendingResetEmail:store.auth.sendingResetEmail,
sentResetEmail:store.auth.sentResetEmail,
resetEmailError:store.auth.resetEmailError,
errorCode:store.auth.errorCode,
otpValid:store.auth.otpValid
  }
})
export default class Login_Form extends React.Component {
  constructor(props){
super(props);
this.state={
  userName :'',
  password:"",
  resetRequest:false,
  lang:"" ,
  showExpiredMsg:false,
  otp:"",
  otpValid: false,
  needToCheckOTP:false,
  otpExpired:false, 
  countdownTime : 0
}
}

  componentDidMount(){
    // var url = new URL(window.location.href);
    // var userObj = {};
    // userObj.user = url.searchParams.get("user");
    // userObj.state = url.searchParams.get("state");
    // userObj.time = url.searchParams.get("time");
    // userObj.brand = url.searchParams.get("brand");
    // userObj.extensions = url.searchParams.get("extensions");
    // userObj.signatures = url.searchParams.get("signatures");
    
    // if(userObj.user!==""){
    //   console.log("request for Canva!")
    //   console.log("url="+window.location.href)
    //   console.log("query part="+window.location.href.split("?")[1])
    //   window.location.href=window.location.origin+"/#/login/?"+window.location.href.split("?")[1]
    //   //this.props.dispatch(redirectCanva(userObj))
    // }
    var langInBrowser = detectBrowserLanguage().substring(0,2);
    console.log("browser lang="+langInBrowser)
    if(langInBrowser!=="fr"){
      langInBrowser = "en";
    }
    this.setState({lang:langInBrowser});
    localStorage.setItem("locale",langInBrowser);
    i18n.changeLanguage(langInBrowser);

    const search = window.location.search;
      const params = new URLSearchParams(search);
      console.log("search="+search);
      //For redirecting to Overview Start
      const redirect = params.get("redirect");
      const authCode = params.get("authCode");
      console.log("***authCode="+authCode)
      if(redirect!==null && redirect=="Overview"){
        console.log("redirect to Overview");
    
      axios.post(`/api/verifyTokenForUser`  ,
      {
        'token': authCode,    
      }).then(response => {
        console.log("response.userName="+JSON.stringify(response.data));
        this.setState({userName:response.data.userName,password:response.data.pwd});
        this.login();
      
      // if(response.data.userName=="test"){
      //   //this.props.history.push(`/myscreen`);
      //   window.location.href=window.location.origin+"/#/myscreen";
      // }
      }).catch(error => {
        this.setState({errorUploading:true})
        // handle your error
      });        
    }
  //For redirecting to Overview End
  }

  login = () => {
    console.log("***in login")
    var lang = localStorage.getItem("locale");
    
    if(this.state.userName!=="" && this.state.password!==""){
      this.props.dispatch(login(this.state))
    }
    
  };
  resetPassword = () =>{
    if(this.state.userName!==""){
      this.setState({resetRequest:true});
    }
  }
  confirmResetPassword = () =>{
      if(this.state.userName!==""){
      this.props.dispatch(sendEmailForReset(this.state.userName,localStorage.getItem("locale")));
      this.setState({resetRequest:false,needToCheckOTP:false});
    }
    
  }

  setFieldValue=({target})=>{
     this.setState({[target.name]:target.value})
   }
componentWillReceiveProps(nextProps){
  if(nextProps.errorCode && nextProps.errorCode=="102"){
    this.setState({showExpiredMsg:true});
  }
  console.log("sendingResetEmail="+nextProps.sendingResetEmail)
  console.log("sentResetEmail="+nextProps.sentResetEmail)
  console.log("resetEmailError="+nextProps.resetEmailError)
console.log("reset request="+this.state.resetRequest)
console.log("nextProps.isAuthenticated="+nextProps.isAuthenticated)
console.log("this.props.isAuthenticated="+this.props.isAuthenticated)
//console.log("***userID="+JSON.parse(localStorage.getItem("userObj")).userID)

if(localStorage.getItem("userObj")!==null 
&& JSON.parse(localStorage.getItem("userObj")).userID=="66ebd8db7b88a242c8e1d028"
&& !nextProps.sentResetEmail
//&& nextProps.isAuthenticated 
//&& JSON.parse(localStorage.getItem("userObj")).resetPwd==false
){
  this.setState({needToCheckOTP:true});
  console.log("***nextProps.otpValid="+nextProps.otpValid)
  if(nextProps.otpValid!==null && nextProps.otpValid=="valid"){
    console.log("OTP is valid!");
    this.setState({otpValid:true})
  } else {
    console.log("OTP is invalid!");
    this.setState({otpValid:false,countdownTime:Date.now() + 120000})
  }
}else{
  this.setState({needToCheckOTP:false});
}
}

resetDialog = () =>{
  this.setState({resetRequest:false});
}



handleLanguageChange = (e) =>{
  console.log("in handle lang="+(e.target.value))
  localStorage.setItem("locale",e.target.value);
  i18n.changeLanguage(e.target.value);  
  this.setState({lang:e.target.value})
}

checkOTP = () =>{
  if(this.state.otp!==""){
    //this.setState({otpValid : true})
    this.props.dispatch(validateOTP(JSON.parse(localStorage.getItem("userObj")).userID,this.state.otp));
  }
}
loginAgain = () =>{
  console.log("Login again!")
  this.setState({needToCheckOTP:false,otpExpired:false,});
  this.props.dispatch(loginAgain());  
  
}

countdownCompleted = () =>{
  console.log("countdown completed!");
  this.setState({otpExpired:true});
}

resendOTP = () =>{
  this.setState({otpExpired:false,otpValid:false});
  this.props.dispatch(login(this.state)); 
}
getRedirectPath() {
  console.log("***In getredirectpath=")
  // const locationState = this.props.location.state
  // if (locationState && locationState.from.pathname) {
  //   return locationState.from.pathname // redirects to referring url
  // } else {
    return '/'
  //}
}


  render() {
    if(this.state.otpValid){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)
    }
    return (
      <div >
    <div style={{height:"96vh",width:"100vw",display:"flex",flexDirection:"column",overflowY:"auto"}}>
    <div style={{height:"10%",width:"100%",textAlign:"right",color:"white"}}>
     <br/>
      {LANGUAGES.map((obj)=>{
      return <label style={{color:"#4fbfbd"}}><input type="radio" value={obj.value} checked={this.state.lang==obj.value} onChange={this.handleLanguageChange} />
      {obj.label}&nbsp;&nbsp;&nbsp;&nbsp;
      </label>
    })
    } 
    </div>
    <div class="login-form d-flex no-shadow   card" style={{height:"70%",width:"20%",background:"transparent",margin:"auto",overflowX:"hidden",overflowY:"hidden"}}>
      <div class="d-flex align-items-center justify-content-center" style={{height:"80px"}}>
        <img   src="images/login.png" height="90%" width="52%"  alt="Card image cap" />
      </div>
      <div class="flex-1 d-flex align-items-start justify-content-center" style={{width:"100%",height:"100%"}}>

      <div class="card-body d-flex flex-column" style={{width:"100%"}}>
      <div style={{width:"100%"}}>
        <Input
                      text={i18n.t("login.username")}
                      ref="userName"
                      name="userName"
                      validator={true}
                      theme={"transparent"}
                      value={this.state.userName}
                      onChange={this.setFieldValue}
                      mandatory
                      disabled={this.state.needToCheckOTP}
                      emptyMessage="Application name can't be empty"
                    />
        </div>
        <div>
                    <Input
                      text={i18n.t("login.password")}
                      ref="password"
                      name="password"
                      validator={true}
                        theme={"transparent"}
                      type="password"
                      value={this.state.password}
                      onChange={this.setFieldValue}
                    mandatory
                    disabled={this.state.needToCheckOTP}

                      emptyMessage="Application name can't be empty"
                    />
            </div>
            {this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
                                                 <div>
                                                 <div style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                <span style={{textAlign:"center",margin:"auto"}}><button style={{border:"0px", color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}}>An OTP has been sent to your registered email ID.</button></span>
                                                </div>
                                                <div style={{textAlign:"center",border:"0px", color:"#4fbfbd",background:"transparent"}}><Countdown date={this.state.countdownTime} onComplete={()=>this.countdownCompleted()}/></div> {/*10000 - 10 seconds */}
                                                 <div>
                                     <Input
                                                   text="OTP"
                                                   ref="otp"
                                                   name="otp"
                                                   validator={true}
                                                      theme={"transparent"}
                                                   type="password"
                                                   value={this.state.otp}
                                                   onChange={this.setFieldValue}
                                                 mandatory


                                                   emptyMessage="OTP can't be empty"
                                                 />
                                                 </div></div>:null
                                                 }
{/* <div >
<ActionButton containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.login()} style={{width:"100%"}}><Trans i18nKey="login.logintext"/></ActionButton>
</div>                                                  */}
<div style={{textAlign:"center",width:"100%"}}>
{this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
<ActionButton containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.checkOTP()} style={{width:"100%"}}>Validate OTP</ActionButton>
:null}                                                 
{!this.state.needToCheckOTP?                                                 
<ActionButton style={{width:"100%"}} containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.login()}>Login</ActionButton>
:null}
{!this.state.needToCheckOTP?                                                 
<div style={{paddingTop:"10px"}}>
<span style={{width:"50%",marginTop:"10px"}}><button style={{textDecoration:"underline",color:"#4fbfbd",background:"transparent",border:"0px",cursor:"pointer"}} onClick={()=>this.resetPassword()}>{this.state.resetRequest}Reset Password</button></span>
 </div>
 :null}
</div>

{/* <div style={{paddingTop:"10px"}}>
 <span style={{textAlign:"center",margin:"auto"}}><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.resetPassword()}>{this.state.resetRequest}<Trans i18nKey="login.reset"/></button></span>
 </div> */}
 <div class="error-msg" style={{height:"20%",paddingTop:"10px"}}>{/*height:"70px"*/}
 {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}><Trans i18nKey="login.loginfailed"/></p>:null}
 {this.props.sendingResetEmail?<ComponentLoader/>:null}
 {this.props.sentResetEmail?<p style={{color:"#4fbfbd"}}><Trans i18nKey="login.resetmessage"/></p>:null}
 {this.props.resetEmailError?<p style={{color:"#B22222"}}><Trans i18nKey="login.validusernamemsg"/></p>:null}
 {this.props.isAuthenticated && this.props.otpValid=="invalid" && this.state.needToCheckOTP && !this.state.otpExpired?<div><p style={{color:"#B22222"}}>OTP entered is invalid! Please try again.</p><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
 {this.props.isAuthenticated && this.state.otpExpired && this.state.needToCheckOTP?<div><p style={{color:"#B22222"}}>OTP has expired! </p><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.resendOTP()}>Resend OTP</button><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
 </div>
 <div style={{width:"100%", display:"flex",flexDirection:"row",minHeight:"10%"}}>
<div style={{width:"50%",textAlign:"left"}}><a href="https://www.intelisa.in/" target="_blank" style={{ color:"#4fbfbd"}}><Trans i18nKey="login.aboutus"/></a></div>
<div style={{width:"50%",textAlign:"right"}}><a href="https://www.intelisa.in/#get-free-trial" target="_blank" style={{ color:"#4fbfbd"}}><Trans i18nKey="login.contactus"/></a></div>
</div>
 
</div>
</div>

</div>
</div>
{this.state.showExpiredMsg?  <DialogBoxLongMessage icon="alert.png" msg1="Your license has expired! Please renew your subscription to access the account." msg2="Please note that all account data will be erased 30 days after suspension, in case the subscription is not renewed.">

{/* <button class="btn btn-danger" onClick={() => this.confirmResetPassword()}><Trans i18nKey="login.yes"/></button> */}
  <button class="btn btn-info" onClick={()=>this.setState({showExpiredMsg:false})}>OK</button>
</DialogBoxLongMessage>:null}
 {this.state.resetRequest?  <DialogBox msg={i18n.t("login.resetconfirm")+this.state.userName+"?"}>

  <button class="btn btn-danger" onClick={() => this.confirmResetPassword()}><Trans i18nKey="login.yes"/></button>
    <button class="btn btn-light" onClick={()=>this.setState({resetRequest:false})}><Trans i18nKey="login.cancel"/></button>
  </DialogBox>:null}
</div>
    );
  }
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    fontSize:"14px"
  }),
  placeholder : (provided, state) => ({
    ...provided,
    fontSize:"14px"
  })
}
