import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../lang/en.json';
import translationFR from '../lang/fr.json';

const resources = {
    en: {
      translation: translationEN
    },
    fr: {
        translation: translationFR
      }
  };
var lang = localStorage.getItem("locale");
console.log("lang="+lang)
if(lang==null){
    lang = "en";
}
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: lang,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    react: {
      wait: true
    }
  });

export default i18n;