import React from "react";
import {login} from "../../redux/actions/authAction.js"
import {connect} from "react-redux";
import Input from "../../components/formcomponent/Input"
import Login_Form from "../../components/Auth/Login_Form"
import Reset_Form from "../../components/Auth/Reset_Form"

import Register_Form from "../../components/Auth/Register_Form"
import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {
    isAuthenticated:store.auth.isAuthenticated,
    resetPWD:store.auth.resetPWD
  }
})
export default class Login extends React.Component {
  constructor(props){
super(props);
this.state={
  componentID:'L_1'

}
  }



   getRedirectPath() {
    const locationState = this.props.location.state
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname // redirects to referring url
    } else {
      return '/'
    }
  }


  render() {
    if(this.props.isAuthenticated && !this.props.resetPWD){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)
    }
    // let { from } = this.props.location.state || { from: { pathname: "/" } };
    // let { redirectToReferrer } = this.state;
    //
    // if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div class="login-page d-flex align-items-center flex-column justify-content-center h-100">

    {this.state.componentID=="L_1"?(!this.props.resetPWD?<Login_Form />:<Reset_Form />):<Register_Form />}
    {this.state.componentID=="L_1"?<a class="pointer d-none " onClick={()=>this.setState({componentID:'R_2'})} >Register</a>:<a class="pointer" onClick={()=>this.setState({componentID:'L_1'})} >Already a member? Sign in.</a>}

</div>
    );
  }
}
