import Shepherd from 'shepherd.js';
export const playlisttour = new Shepherd.Tour({
  useModalOverlay: true,
        defaultStepOptions: {
            classes: 'tour-guide',
            scrollTo: false,
            
            when: {
                /*show() {
                  console.log("IN SHOW!!")
                  const currentStepElement = playlisttour.currentStep.el;
                  const header = currentStepElement.querySelector('.shepherd-footer');
                  //create progress holder
                  const progress = document.createElement('div');
                  //create the progress bar
                  const innerBar = document.createElement('span');
                  //calculate the progress in percentages
                  const progressPercentage = ((playlisttour.steps.indexOf(playlisttour.currentStep) + 1)/playlisttour.steps.length)*100 + '%';
              
                  //add class to the progress holder
                  progress.className='shepherd-progress-bar';
                  //add the width dynamically
                  innerBar.style.width=progressPercentage;
                  //if it is only one button, expand progress holder
                  if (document.getElementsByClassName('shepherd-button').length==1) {
                      progress.style.minWidth = '260px';
                  }
                  progress.appendChild(innerBar);                  
                  header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                  
                  /*const progress = document.createElement('div');
                  //progress.className='progress-bar';
                  progress.style.display = 'flex';
                  progress.style.flexDirection = 'row';
                  progress.style.left=0;
                  for(var i=1;i<=playlisttour.steps.length;i++){
                    const dot = document.createElement('span');
                    if(i==playlisttour.steps.indexOf(playlisttour.currentStep) + 1){
                      dot.className='coloureddot';
                    } else {
                      dot.className='dot';
                    }
                    const space = document.createElement('div');
                    space.style.width='8px';
                    space.append('&nbsp;');
                    progress.appendChild(dot);
                    progress.appendChild(space);
                  }
                  if (document.getElementsByClassName('shepherd-button').length==1) {
                    progress.style.minWidth = '260px';
                  }
                  header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                  */
                /*}*/
            } 
        }
    });
    function waitForElementToDisplay(selector) {
        console.log("222")
        return new Promise(function (resolve) {
            (function checkIfElementExists() {
              console.log("checking!!")
                if (document.querySelector(selector) !== null) {
                  console.log("***333")
                    resolve();
                } else {
                    setTimeout(checkIfElementExists, 500);
                }
            })();
        })
      }
      
      playlisttour.addStep(
        {
          id: 'intro', //Welcome
          //attachTo: { element: '.first-element', on: 'bottom' },
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              setTimeout(function () {
                window.scrollTo(0, 0);
                resolve();
              }, 500);
            });
          },
          buttons: [
            {
              classes: 'tutorial_button_secondary',
              text: 'Exit',
              type: 'cancel',
              action:playlisttour.complete
            },     
            {
              classes: 'tutorial_button_primary',
              text: "Let's get started",
              type: 'next',
              action: playlisttour.next
            }
          ],
          //classes: 'custom-class-name-1 custom-class-name-2',
                    
          //title: 'Welcome to Intelisa',
          text: ['We have refreshed our Playlist page! In this tutorial, we will walk you through the steps in creating a playlist. A playlist is a sequence of videos, images and documents, that eases the process of scheduling media across screens. '],
          // when: {
          //   show: () => {
          //     console.log('show step');
          //   },
          //   hide: () => {
          //     console.log('hide step');
          //   }
          // }
        }
      );

      playlisttour.addStep(
        {
          id: 'playlistAdd', //My creatives
          //title:'Click the "My Creatives" tab',
          text: ['Click on the "+" sign for creating a new playlist'],
          arrow:true,
          attachTo: {
            //element: '.create',
            element:'#playlistAddButton',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
                classes: 'tutorial_button_secondary',
                text: 'Exit',
                type: 'cancel',
                action:playlisttour.complete
              }, 
              {
                classes: 'tutorial_button_secondary',
                text: 'Previous',
                action: () =>{
                  return playlisttour.back()
                }
              }, 
            {
              classes: 'btn btn-primary',
              text: 'Next',
              action: () =>{
                //tour.next
                const selector = document.getElementById('playlistAddButton')
                selector.click()
                return playlisttour.next()
              }
            }
          ],
         
        });

        playlisttour.addStep(
            {
              id: 'playlistname', //My creatives
              //title:'Click the "My Creatives" tab',
              text: ['Enter a Playlist name'],
              arrow:true,
              attachTo: {
                //element: '.create',
                element:'#Playlistname',
                on: 'bottom'
              },
              classes: 'example-step-extra-class',
              buttons: [
                {
                    classes: 'tutorial_button_secondary',
                    text: 'Exit',
                    type: 'cancel',
                    action:playlisttour.complete
                  },  
                  {
                    classes: 'tutorial_button_secondary',
                    text: 'Previous',
                    action: () =>{
                      const cancelButton = document.getElementById('cancelButton');
                      cancelButton.click();
                      return playlisttour.back()
                    }
                  },
                {
                  classes: 'btn btn-primary',
                  text: 'Next',
                  action: () =>{
                    //tour.next
                    // const selector = document.getElementById('playlistAddButton')
                    // selector.click()
                    return playlisttour.next()
                  }
                }
              ],
             
            });

playlisttour.addStep(
    {
        id: 'playlistsave', //My creatives
        //title:'Click the "My Creatives" tab',
        text: ['Hit Save'],
        arrow:true,
        attachTo: {
        //element: '.create',
        element:'#savePlaylist',
        on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: [
            {
                classes: 'tutorial_button_secondary',
                text: 'Exit',
                type: 'cancel',
                action:playlisttour.complete
              },  
              {
                classes: 'tutorial_button_secondary',
                text: 'Previous',
                action: () =>{                  
                  return playlisttour.back()
                }
              },
        {
            classes: 'btn btn-primary',
            text: 'Next',
            action: () =>{
            //tour.next
            // const selector = document.getElementById('playlistAddButton')
            // selector.click()
            const cancelButton = document.getElementById('cancelButton');
            cancelButton.click();
            return playlisttour.next()
            }
        }
        ],
        
    });   
    
    playlisttour.addStep(
        {
            id: 'creativesDropDown', //My creatives
            //title:'Click the "My Creatives" tab',
            text: ['Click on a video. Drag and drop it into the empty section.'],
            arrow:true,
            attachTo: {
            //element: '.create',
            element:'#creativesDropDown',
            on: 'right'
            },
            classes: 'example-step-extra-class',
            buttons: [
                {
                    classes: 'tutorial_button_secondary',
                    text: 'Exit',
                    type: 'cancel',
                    action:playlisttour.complete
                  },  
                  {
                    classes: 'tutorial_button_secondary',
                    text: 'Previous',
                    action: () =>{
                      const selector = document.getElementById('playlistAddButton')
                      selector.click();
                      return playlisttour.back()
                    }
                  },
            {
                classes: 'btn btn-primary',
                text: 'Next',
                action: () =>{
                //tour.next
                // const selector = document.getElementById('playlistAddButton')
                // selector.click()
                
                return playlisttour.next()
                }
            }
            ],
            
        });       

        playlisttour.addStep(
            {
                id: 'orientationDropDown', //My creatives
                //title:'Click the "My Creatives" tab',
                text: ['You can also select from either portrait or landscape media to be dropped into the playlist, by clicking here.'],
                arrow:true,
                attachTo: {
                //element: '.create',
                element:'#orientationDropDown',
                on: 'top'
                },
                classes: 'example-step-extra-class',
                buttons: [
                    {
                        classes: 'tutorial_button_secondary',
                        text: 'Exit',
                        type: 'cancel',
                        action:playlisttour.complete
                      },  
                      {
                        classes: 'tutorial_button_secondary',
                        text: 'Previous',
                        action: () =>{
                          return playlisttour.back()
                        }
                      },
                {
                    classes: 'btn btn-primary',
                    text: 'Next',
                    action: () =>{
                    //tour.next
                    // const selector = document.getElementById('playlistAddButton')
                    // selector.click()
                    
                    return playlisttour.next()
                    }
                }
                ],
                
            });  
        
            playlisttour.addStep(
                {
                    id: 'creativeGroupDropDown', //My creatives
                    //title:'Click the "My Creatives" tab',
                    text: ['You can also select media from different creative groups, by clicking here.'],
                    arrow:true,
                    attachTo: {
                    //element: '.create',
                    element:'#creativeGroupDropDown',
                    on: 'top'
                    },
                    classes: 'example-step-extra-class',
                    buttons: [
                        {
                            classes: 'tutorial_button_secondary',
                            text: 'Exit',
                            type: 'cancel',
                            action:playlisttour.complete
                          }, 
                          {
                            classes: 'tutorial_button_secondary',
                            text: 'Previous',
                            action: () =>{
                              return playlisttour.back()
                            }
                          }, 
                    {
                        classes: 'btn btn-primary',
                        text: 'Next',
                        action: () =>{
                        //tour.next
                        // const selector = document.getElementById('playlistAddButton')
                        // selector.click()
                        
                        return playlisttour.next()
                        }
                    }
                    ],
                    
                });  

                playlisttour.addStep(
                    {
                        id: 'clearFilters', //My creatives
                        //title:'Click the "My Creatives" tab',
                        text: ['To clear all filters at any time, you can use this button'],
                        arrow:true,
                        attachTo: {
                        //element: '.create',
                        element:'#clearFilters',
                        on: 'top'
                        },
                        classes: 'example-step-extra-class',
                        buttons: [
                            {
                                classes: 'tutorial_button_secondary',
                                text: 'Exit',
                                type: 'cancel',
                                action:playlisttour.complete
                              },  
                              {
                                classes: 'tutorial_button_secondary',
                                text: 'Previous',
                                action: () =>{
                                  return playlisttour.back()
                                }
                              },
                        {
                            classes: 'btn btn-primary',
                            text: 'Next',
                            action: () =>{
                            //tour.next
                            // const selector = document.getElementById('playlistAddButton')
                            // selector.click()
                            
                            return playlisttour.next()
                            }
                        }
                        ],
                        
                    });   
                    
        playlisttour.addStep(
            {
                id: 'countTutorial', //My creatives
                //title:'Click the "My Creatives" tab',
                text: ['Change the "Count" to play the video multiple times'],
                arrow:true,
                attachTo: {
                //element: '.create',
                element:'#countTutorial',
                on: 'top'
                },
                classes: 'example-step-extra-class',
                buttons: [
                    {
                        classes: 'tutorial_button_secondary',
                        text: 'Exit',
                        type: 'cancel',
                        action:playlisttour.complete
                      },  
                      {
                        classes: 'tutorial_button_secondary',
                        text: 'Previous',
                        action: () =>{
                          return playlisttour.back()
                        }
                      },
                {
                    classes: 'btn btn-primary',
                    text: 'Next',
                    action: () =>{
                    //tour.next
                    // const selector = document.getElementById('playlistAddButton')
                    // selector.click()
                    
                    return playlisttour.next()
                    }
                }
                ],
                
            });    
            
            playlisttour.addStep(
                {
                    id: 'durationTutorial', //My creatives
                    //title:'Click the "My Creatives" tab',
                    text: ['Change the "Duration" to display an Image for the required duration'],
                    arrow:true,
                    attachTo: {
                    //element: '.create',
                    element:'#durationTutorial',
                    on: 'top'
                    },
                    classes: 'example-step-extra-class',
                    buttons: [
                        {
                            classes: 'tutorial_button_secondary',
                            text: 'Exit',
                            type: 'cancel',
                            action:playlisttour.complete
                          },  
                          {
                            classes: 'tutorial_button_secondary',
                            text: 'Previous',
                            action: () =>{
                              return playlisttour.back()
                            }
                          },
                    {
                        classes: 'btn btn-primary',
                        text: 'Next',
                        action: () =>{
                        //tour.next
                        return playlisttour.next()
                        }
                    }
                    ],
                    
                });    
                
                playlisttour.addStep(
                    {
                        id: 'playlistcollapse', 
                        text: ['This will collapse the Playlist section and expand the Library section.'],
                        arrow:true,
                        attachTo: {
                        //element: '.create',
                        element:'#collapsePlaylistsTutorial',
                        on: 'top'
                        },
                        classes: 'example-step-extra-class',
                        buttons: [
                            {
                                classes: 'tutorial_button_secondary',
                                text: 'Exit',
                                type: 'cancel',
                                action:playlisttour.complete
                              },  
                              {
                                classes: 'tutorial_button_secondary',
                                text: 'Previous',
                                action: () =>{
                                  return playlisttour.back()
                                }
                              },
                        {
                            classes: 'btn btn-primary',
                            text: 'Next',
                            action: () =>{
                            //tour.next
                            const selector = document.getElementById('collapsePlaylistsTutorial')
                            selector.click();                            
                            return playlisttour.next()
                            }
                        }
                        ],
                        
                    });  
                    
                    playlisttour.addStep(
                    {
                        id: 'playlistoriginal', //My creatives
                        //title:'Click the "My Creatives" tab',
                        text: ['This will bring it back to the original view.'],
                        arrow:true,
                        attachTo: {
                        //element: '.create',
                        element:'#collapsePlaylistsTutorial',
                        on: 'top'
                        },
                        classes: 'example-step-extra-class',
                        buttons: [
                            {
                                classes: 'tutorial_button_secondary',
                                text: 'Exit',
                                type: 'cancel',
                                action:playlisttour.complete
                              },  
                              {
                                classes: 'tutorial_button_secondary',
                                text: 'Previous',
                                action: () =>{
                                  const selector = document.getElementById('collapsePlaylistsTutorial')
                                  selector.click(); 
                                  return playlisttour.back()
                                }
                              },
                        {
                            classes: 'btn btn-primary',
                            text: 'Next',
                            action: () =>{
                            //tour.next
                            const selector = document.getElementById('collapsePlaylistsTutorial')
                            selector.click();                            
                            return playlisttour.next()
                            }
                        }
                        ],
                        
                    });  
                    
                    
                    playlisttour.addStep(
                        {
                            id: 'collapseLibrary', 
                            text: ['This will collapse the Library Section and expand the Playlist section.'],
                            arrow:true,
                            attachTo: {
                            //element: '.create',
                            element:'#collapseLibraryTutorial',
                            on: 'top'
                            },
                            classes: 'example-step-extra-class',
                            buttons: [
                                {
                                    classes: 'tutorial_button_secondary',
                                    text: 'Exit',
                                    type: 'cancel',
                                    action:playlisttour.complete
                                  },  
                                  {
                                    classes: 'tutorial_button_secondary',
                                    text: 'Previous',
                                    action: () =>{
                                      const selector = document.getElementById('collapsePlaylistsTutorial')
                                      selector.click(); 
                                      return playlisttour.back()
                                    }
                                  },
                            {
                                classes: 'btn btn-primary',
                                text: 'Next',
                                action: () =>{
                                //tour.next
                                const selector = document.getElementById('collapseLibraryTutorial')
                                selector.click();                                
                                return playlisttour.next()
                                }
                            }
                            ],
                            
                        }); 


                        playlisttour.addStep(
                            {
                                id: 'libraryOriginal', 
                                text: ['This will bring it back to the original view.'],
                                arrow:true,
                                attachTo: {
                                //element: '.create',
                                element:'#collapseLibraryTutorial',
                                on: 'top'
                                },
                                classes: 'example-step-extra-class',
                                buttons: [
                                    {
                                        classes: 'tutorial_button_secondary',
                                        text: 'Exit',
                                        type: 'cancel',
                                        action:playlisttour.complete
                                      },  
                                      {
                                        classes: 'tutorial_button_secondary',
                                        text: 'Previous',
                                        action: () =>{
                                          const selector = document.getElementById('collapseLibraryTutorial')
                                          selector.click(); 
                                          return playlisttour.back()
                                        }
                                      },
                                {
                                    classes: 'btn btn-primary',
                                    text: 'Next',
                                    action: () =>{
                                    //tour.next
                                    // const selector = document.getElementById('collapsePlaylistsTutorial')
                                    // selector.click()
                                    const selector = document.getElementById('collapseLibraryTutorial')
                                    selector.click();
                                    const selector1 = document.getElementById('playlistAddButton')
                                    selector1.click();
                                    return playlisttour.next()
                                    }
                                }
                                ],
                                
                            }); 

                            playlisttour.addStep(
                                {
                                    id: 'subplaylist', //My creatives
                                    //title:'Click the "My Creatives" tab',
                                    text: ['A Sub-playlist – allows you to re-use this playlist inside a Master Playlist. All steps of creation are same as that of Master Playlist.'],
                                    arrow:true,
                                    attachTo: {
                                    //element: '.create',
                                    element:'#subplaylisttutorial',
                                    on: 'top'
                                    },
                                    classes: 'example-step-extra-class',
                                    buttons: [
                                        {
                                            classes: 'tutorial_button_secondary',
                                            text: 'Exit',
                                            type: 'cancel',
                                            action:playlisttour.complete
                                          },  
                                          {
                                            classes: 'tutorial_button_secondary',
                                            text: 'Previous',
                                            action: () =>{
                                              const cancelButton = document.getElementById('cancelButton');
                                              cancelButton.click();
                                              const selector = document.getElementById('collapseLibraryTutorial')
                                              selector.click();
                                              return playlisttour.back()
                                            }
                                          },
                                    {
                                        classes: 'btn btn-primary',
                                        text: 'Complete',
                                        action: () =>{
                                        //tour.next
                                        const cancelButton = document.getElementById('cancelButton');
                                        cancelButton.click();
                                        playlisttour.complete();
                                        //return playlisttour.next()
                                        }
                                    }
                                    ],
                                    
                                });                             
                        