import React,{Component} from "react";
import "../../../css/widgets/widget.css";
export default class Widget extends Component {
  render(){
    return(
      <div class="modal d-flex align-items-center justify-content-center" style={{zIndex:1500,backgroundColor:"#0000009e"}} >
      <div class="card  widget bg-white" {...this.props}>
  <div class="card-header">
  <div class="card-caption">
  <div class="card-icon">

  </div>
  <div class="card-title">
  <span class="main-title">{this.props.title}</span>
    <span class="sub-title">{this.props.subTitle}</span>
  </div>
  </div>

  <div class="action-buttons">
  <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
    <a class="btn btn-circle  btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>

  </div>
  </div>
  <div class="card-body d-flex justify-content-center" style={{overflowX:"hidden",overflowY:"auto"}}>
    {this.props.children[0]}
  </div>
{this.props.children[1]?  <div class="card-footer bg-white d-flex justify-content-end align-items-center" style={{maxHeight:"60px"}}>
  {this.props.children[1]}
  </div>:null}
</div>
  </div>
    )
  }
}